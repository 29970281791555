.paper.security>p>div>main#container>div#terminal {
    height: 7vh !important;
}

h4 {
    color: #7dacb5;
}

p.list-items>span {
    display: list-item;
    list-style-type: "》";
    padding-inline-start: 1ch;
}