.paper.ls-R {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    direction: rtl;
}
.ls-R-result {
    width: 100%;
    height: 70.5vh;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/ls-R.png') no-repeat center 155px;
    background-size: 70%;
    justify-content: space-between;
}
.bold.blue.medium-text{
    color: #12488A !important;
}
.bold.green.medium-text{
    color:#279C67;
}
.bold.orange.medium-text{
    color:#eea108;
}