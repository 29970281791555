.image-on-page-maltego {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-plan.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 17%;

}

.image-on-page-maltego-Transform-Hub {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-hubs.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 76%;
}

.image-on-page-maltego-Maltego-Community {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-plan-ce.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 76%;
}
.image-on-page-maltego-install-transform{
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-hub.png') no-repeat 17px 126px;
    height: 30vh;
    background-size: 26%;
}

.image-on-page-maltego-2-hubs{
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-2-hubs.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 56%;
}

.image-on-page-maltego-Investegate{
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-g1.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 30%;
}
.image-on-page-maltego-person{
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/maltego-person2.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 76%;
}
.image-on-page-maltego-run-transformation{
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/run-t.png') no-repeat 17px 126px;
    height: 50vh;
    background-size: 36%;
}

.paper.Maltego span {
    display: inline-block !important;
    width: 75%;
    /* border:1px solid red; */
}