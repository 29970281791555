.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  background-color: #F1F1F1;
  /* #282c34;   #76cd47*/
  /* min-height: 100vh; */
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Amagro";
  src: url("./assets/fonts/amagro/Amagro.ttf");
}

div.test div#questionnaire-container>.MuiTable-root>tbody>tr>td:nth-child(2) {
  text-align: right;
  direction: rtl !important;
}

div.buttons-div p.testResults-total-score{
  display: inline-block;
  /* border:1px solid red; */
  width: 36%;
  height: 28pt;
  line-height: 28pt;
  font-size:21pt;
  color:#4d16e6;
}
.MuiTreeItem-content.Mui-selected {
  background-color: #fb6175 !important;
}

.MuiTreeItem-content.Mui-selected>.MuiTreeItem-label {
  color: #fff !important;

}

div.paper>p.c9.links>a {
  display: inline-block;
  width: 50vw;
  direction: ltr;
  text-align: left;
  font-size: 17pt;
  color: cadetblue;
  text-align: left;
  /* border:1px solid red; */
}

.image-on-page {
  position: relative;
  left: 0px;
  width: 100%;
  display: inline-block;
  height: 17vh;
}


.break {
  flex-basis: 100%;
  height: 0;
}

.paper {
  overflow-y: scroll;
}

/* .paper p b {
  color: #FEAC00;
  display: block;
  margin: 5px;
  margin-top: 1pt;
} */

h4 {
  color: #7dacb5;
  direction: rtl;
}





p>span.c0 {
  display: inline !important;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 17pt;
  font-family: "Arial";
  font-style: normal;
}


p>span.c1 {
  display: inline !important;
  font-size: 17pt;
  font-weight: 700;
}

p>span.c2 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}

.c13 {
  color: #ff0000;
}

.c15 {
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}

.c14 {
  color: #0000ff;
}
.c17{
  color:#61dafb;
}

p>span.c4 {
  display: inline !important;
  font-size: 17pt;
}

p.c5 {

  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

p.c9 {
  position: relative;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: right;
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
  direction: rtl;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 70%;
  /* border: 1px solid red; */
  left: 26%;
}

p.c9>span {}

p.list-items>span {
  display: list-item;
  list-style-type: "》";
  padding-inline-start: 1ch;
}

span.ltr {
  direction: ltr !important;
  text-align: left;
}

p b {
  direction: rtl !important;
}


.paper {
  position: relative;
  overflow: auto;
  width: 90% !important;
  height: 55% !important;
  /* width: fit-content !important;; */
  height: fit-content !important;
  padding: 10px;
  text-align: right;
  /* border: 1px solid red; */
}

.paper h4 {
  /* display: inline-block; */
}

div.paper>div.img-div {
  /* border: 1px solid red; */
  position: fixed;
  top: 0px;
  right: 0px;
  /* display: inline-block; */
}

div.paper>div.img-div>img {
  display: inline-block;
  margin-bottom: 10px;
  width: 126px !important;
  border: none;
  padding-bottom: 10px;
}

.paper.medium-display-column p {
  margin: 13px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.paper.medium-display-column p>span {
  direction: rtl;
  display: block;
  margin: 5px;
  width: 53%;
  text-align: justify;
  /* border: 1px solid red; */
}

.paper.medium-display-row p {
  width: 50%;
  margin: 13px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* border: 1px solid red; */
}

.paper.medium-display-row p>span {
  direction: rtl;
  display: block;
  margin: 5px;
  text-align: justify;
  /* border: 1px solid red; */
}

div#terminal{
  /* height: 21pt; */
}
.shell-terminal {
  /* border:1px solid blue; */
  width: 50%;
  /* height: 21pt; */
}

.shell-terminal section#terminal__body{
  /* border:1px solid red; */
  /* height: 21pt; */
}