div.paper.Arp-Poisioning {
    /* background-color: #2232; */
}
.image-on-page-Arp-Poisioning1 {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/Arp-Poisioning1.png') no-repeat 17px 51px;
    height: 49vh;
    background-size: 76%;
    
}

.image-on-page-Arp-Poisioning2 {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/Arp-Poisioning2.png') no-repeat 17px 51px;
    height: 49vh;
    background-size: 76%;
    
}

.image-on-page-Arp-Poisioning3 {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/Arp-Poisioning3.png') no-repeat 17px 121px;
    height: 57vh;
    background-size: 76%;
    
}

.image-on-page-Arp-Poisioning4 {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/Arp-Poisioning4.png') no-repeat 17px 151px;
    height: 57vh;
    background-size: 76%;
    
}