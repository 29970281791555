.topicviewer-container {
    display: flex;
    width:100%;
    height: 100%;
    flex-direction: row;
    /* justify-content: flex-start; */
    /* border: 1px solid red; */
}

div.page-content{
    width: 83%;
    /* border:1px solid green; */
}