.image-on-page-nmap-3-way-handshake {
    background: rgb(255, 255, 255) url('./../../../../../../../assets/Images/3-way-handshake.png') no-repeat 13% 0px;
    height: 13vh;
    background-size: 26%;
    /* border:1px solids red; */
}

.image-on-page-nmpa-output-0{
    background: rgb(255, 255, 255) url('./../../../../../../../assets/Images/nmap-output.png') no-repeat 0% 150px;
    height: 29vh;
    background-size: 21%;
    /* border:1px solids red; */
}