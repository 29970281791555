ol > li 
{ 
    direction: ltr;
    text-align: left;
}

span.tree-command{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border:1px solid red; */
}

span.tree-command span{
    direction: ltr;
    text-align: left;
    /* border: 1px solid red; */
}