.regexcomponent {
    border: 1px solid #808080;
    padding-top: 1rem !important;
    margin: 16px;
    margin-left: 13.7%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 16px;
    width: 70.7%;
    font-family: Verdana, sans-serif, Geneva;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: justify;
    color: rgb(24, 23, 23);
    position: relative;

}

.regexcomponent span {
    margin: 0px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.paper.regexcomponent input {
    line-height: 24px;
}

.patternbox {
    padding: 0 10px 0 10px;
}

.textbox-container {
    display: inline-block;
    position: relative;
    width: 78%;

}

.slash:first-child {
    left: 5px;
}

.slash {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
}

.slash:last-child {
    right: 5px;
    direction: ltr;
}

.paper span.match {
    display: inline-block;
    background-color: yellow
}

.textbox-container {
    display: inline-block;
    position: relative;
    width: 78%
}