#terminal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 26px;
  width: 100%;
  margin-top:10px;
  /* border: 1px solid red; */
}

#checkmark-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 80px;
  /* border: 1px solid red; */
}

.cursor {
  position: relative;
  display: flex;
  width: 75%;
}

.cursor i {
  position: absolute;
  width: 1px;
  height: 80%;
  background-color: gray;
  left: 5px;
  top: 10%;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.cursor input:focus+i {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

#Terminal-ID {
  background-color: rgb(0, 0, 0);
  outline: none;
  border: none;
  color: white;
  font-family: monospace;
  font-weight: 800;
  width: 75%;
}

.Cur_Input_Line {
  position: relative;
  direction: ltr;
  display: flex;
  flex-direction: row;
  align-content: space-evenly;
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 34px;
  /* border: 1px solid red; */
  margin-top: 5px;
  /* margin-right: 26px; */
  font-size: 20px;
}

.Cur_Input_Line>span {
  font-family: monospace;
  font-weight: 700;
  /* border: 1px solid red; */
}

.Ter_Green {
  color: chartreuse
}

.Ter_Blue {
  color: cyan
}

.Ter_Gray {
  color: rgb(167, 166, 166);
}


.Cur_Input_Line>input {


  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: monospace;

  border: none;
  border-color: transparent;
  background-color: rgb(0, 0, 0);
  width: 73%;
  /* border:1px solid red; */
}

.Cur_Input_Line>input:focus {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: monospace;

  border: none;
  border-color: transparent;
  outline: none;
  background-color: rgb(0, 0, 0);

  width: 75%;
  caret-color: #000;
}


.Ter_Input {
  /* width: 100%; */

}

@keyframes Blink_Ani {
  from {
    background-color: #000;
  }

  to {
    background-color: #fff;
  }
}

.Blink {
  position: absolute;
  left: 321px;
  top: 3px;
  height: 20px;
  width: 2px;
  background-color: #dcebec;
  opacity: 1;
  z-index: 5;
  animation: blink 1s step-start 0s infinite;
  /* border:1px solid red; */

}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}

.blink {
  animation: blink 1s step-start 0s infinite;
}

/*
  Checkmark
  */

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  align-self: center;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.no-visible {
  /* visibility: hidden; */
  display: none;
}

.visible {
  /* visibility: visible; */
}