.para-after-image-stdin-stdout{
    width: 100%;
    margin-top: 26px;
    height: 13vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/stdin-out-1.png') no-repeat center;
    background-size: 40%;
    /* border: 1px solid red; */
}
.para-after-image-stderr{
    width: 100%;
    margin-top: 26px;
    height: 13vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/stdin-out-permission-denied.png') no-repeat center;
    background-size: 40%;
    /* border: 1px solid red; */
}
.para-after-image-stderr-redirect{
    width: 100%;
    margin-top: 26px;
    height: 13vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/stderr-redirect-dev-null.png') no-repeat center;
    background-size: 40%;
    /* border: 1px solid red; */  
}