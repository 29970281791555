div#topmenu-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    align-self: flex-start;
    padding: 7px;
    direction: rtl;
    font-family: "Amagro";
    font-size: 15pt;
    color: #070707;
    -ms-flex-pack: justify;
    margin: 0 auto;
    height: 70px;
    width: 100%;
    background-color: #30415C
        /* border: 3px solid green; */
}


div#topmenu-container {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: right;
    /* border:1px solid red; */
}
div#topmenu-container h1 {
    width: 90vw;
    height: 100%;
    text-align: center;
    line-height: 50px;
    color: #eea108;
    font-size: 26pt;
    /* border:1px solid greenyellow; */
}

div#opening p>span {
    font-size: 17px;
}

div#opening>div.img-div>img {
    width: 126px;
    border: none;
}

.opening-summary p span {
    font-size: 0.8vw !important;
}