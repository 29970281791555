.paper.find-perm > p > div > main#container > div#terminal {
    height: 7vh !important;
    width: 60vw;
}

.paper.find-perm>p>div>main#container>div#terminal>section#terminal__body>div#terminal__prompt>span#terminal__prompt--cursor {
    min-width: 75%;
    /* border:1px solid red; */
}
td {
    direction: ltr !important;
}
td:nth-child(1) {
    direction: rtl !important;
}