p b.radio-qustion {
    font-size: 26pt;
}

div.quiz-container {
    direction: rtl;
}

div.tcpHandShake {

    background: url('./../../assets/Images/tcp/3way.png') no-repeat center;
    background-size: 36%;
}

div.rtt {

    background: url('./../../assets/Images/tcp/rtt.png') no-repeat center;
    background-size: 26%;
}


div.wireshark {

    background: url('./../../assets/Images/tcp/wireshark.png') no-repeat center;
    background-size: 36%;
}

div.Ghost-Byte {

    background: url('./../../assets/Images/tcp/Ghost-Byte.png') no-repeat center;
    background-size: 26%;
}

div.window-scale {

    background: url('./../../assets/Images/tcp/window-scale.png') no-repeat center;
    background-size: 36%;
}

div.ZeroWindow {

    background: url('./../../assets/Images/tcp/ZeroWindow.png') no-repeat center;
    background-size: 36%;
}

div.dup-acks {

    background: url('./../../assets/Images/tcp/dup-acks.png') no-repeat center;
    background-size: 36%;
}