img {
    border-radius: 50%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 26px;
}

.profile-div {
    background-color: #4aada9;
    color: rgb(222, 238, 219);
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    border-end-start-radius: 13px;
    border-end-end-radius: 13px;
    display: flex;
    flex-direction: row;
    align-items: center; /*flex-start;*/
    width: 256px; /*199px;*/
    height: 39px;

}

.profile-div p {
    margin: 5px;
    font-size: 7.5pt;
    padding: 3px;
}

.hidden {
    display: none;
}