div.module-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 400px;
    cursor: pointer;
    /* margin: 0.5%; */
    background-color: #1A2332;
    color: #FFFFFF;
    background: #1A2332 url('./../../../../assets/Images/linux-fundamentals.png') no-repeat;
    background-size: contain;
    border-radius: 7px;
    /* border:1px solid red; */
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13pt;
    text-align: right;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

div.cube-wrapper-div {
    /* border:1px solid red; */
    width: 26%;
    margin: 0.5%;
    height: 457.578px;
}

div.cube-wrapper-div span {
    display: block;
    /* width: 100%; */
    text-align: left;
    font-size: 17pt;
    padding: 7pt;
    align-self: flex-end;
    /* border: 1px solid red; */
}


div.cube-wrapper-div span span.MuiLinearProgress-determinate {
    padding: 3pt;
}

div.subject-cube-footer {
    position: relative;
    bottom: 13%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 98%;
    height: 45px;
    margin-bottom: 3%;

    /* border:3px solid blue; */
}

span.subject-cube-status {
    /* border:1px solid red; */
}

span.progressbar-span {
    width: 17vw;
    /* border:1px solid red;  */
}

span.finished-topics {
    display: inline-block !important;
    font-size: 13pt !important;
}

button.MuiButtonBase-root {
    font-size: 9pt;
    height: 4vh;
    width: 88px !important;
    margin: 5px;

    /* border:1px solid red; */
}

div.module-container.Storage-And-Measurement-Units {
    background: #1A2332 url('./../../../../assets/Images/Storage-And-Measurement-Units.png') no-repeat;
    background-size: contain;
}

div.module-container.Bases-Conversion {
    background: #1A2332 url('./../../../../assets/Images/Bases-Conversion.png') no-repeat;
    background-size: contain;
}



div.module-container.Two-Computers-Communicate {
    background: #1A2332 url('./../../../../assets/Images/Two-Computers-Communicate.png') no-repeat;
    background-size: contain;
}

div.module-container.More-Than-Two-Computers-Communicate {
    background: #1A2332 url('./../../../../assets/Images/More-Than-Two-Computers-Communicate.png') no-repeat;
    background-size: contain;
}

div.module-container.VLAN {
    background: #1A2332 url('./../../../../assets/Images/VLAN.png') no-repeat;
    background-size: contain;
}

div.module-container.Router {
    background: #1A2332 url('./../../../../assets/Images/Router.png') no-repeat;
    background-size: contain;
}

div.module-container.DHCP {
    background: #1A2332 url('./../../../../assets/Images/DHCP.png') no-repeat;
    background-size: contain;
}

div.module-container.Static-Routing {
    background: #1A2332 url('./../../../../assets/Images/Static-Routing.png') no-repeat;
    background-size: contain;
}

div.module-container.Classful-Net-Masks {
    background: #1A2332 url('./../../../../assets/Images/Classful-Net-Masks.png') no-repeat;
    background-size: contain;
}

div.module-container.CIDR-Notation {
    background: #1A2332 url('./../../../../assets/Images/CIDR-Notation.png') no-repeat;
    background-size: contain;
}

div.module-container.Magic-Number {
    background: #1A2332 url('./../../../../assets/Images/Magic-Number.png') no-repeat;
    background-size: contain;
}

div.module-container.Server-And-Cleint {
    background: #1A2332 url('./../../../../assets/Images/Server-And-Cleint.png') no-repeat;
    background-size: contain;
}

div.module-container.DNS {
    background: #1A2332 url('./../../../../assets/Images/DNS.png') no-repeat;
    background-size: contain;
}

div.module-container.PORTS {
    background: #1A2332 url('./../../../../assets/Images/PORTS.png') no-repeat;
    background-size: contain;
}

div.module-container.Public-and-Private-IP {
    background: #1A2332 url('./../../../../assets/Images/Public-and-Private-IP.png') no-repeat;
    background-size: contain;
}

div.module-container.TCP-IP {
    background: #1A2332 url('./../../../../assets/Images/TCP-IP.png') no-repeat;
    background-size: contain;
}

div.module-container.Wireshark {
    background: #1A2332 url('./../../../../assets/Images/Wireshark.png') no-repeat;
    background-size: contain;
}

div.module-container.WiFi-Handshake {
    background: #1A2332 url('./../../../../assets/Images/WiFi-Handshake.png') no-repeat;
    background-size: contain;
}






div.module-container.Linux {
    background: #1A2332 url('./../../../../assets/Images/linux-fundamentals.png') no-repeat;
    background-size: contain;
}

div.module-container.Regular.Expression {
    background: #1A2332 url('./../../../../assets/Images/regex-website.png') no-repeat;
    background-size: contain;
}

div.module-container.darknet {
    background: #1A2332 url('./../../../../assets/Images/darknet-website.png') no-repeat;
    background-size: contain;
}

div.module-container.hackers-type {
    background: #1A2332 url('./../../../../assets/Images/hackers-type-webapp.png') no-repeat;
    background-size: contain;
}

div.module-container.Surfing.Anonymously {
    background: #1A2332 url('./../../../../assets/Images/Surfing-Anonymously.png') no-repeat;
    background-size: contain;
}

div.module-container.Reconnaissance {
    background: #1A2332 url('./../../../../assets/Images/Reconnaissance.png') no-repeat;
    background-size: contain;
}

div.module-container.Man.In.The.Middle.Attacks {
    background: #1A2332 url('./../../../../assets/Images/Man-In-The-Middle-Attacks.png') no-repeat;
    background-size: contain;
}

div.module-container.Encryptions {
    background: #1A2332 url('./../../../../assets/Images/Encryptions.png') no-repeat;
    background-size: contain;
}

div.module-container.SSH {
    background: #1A2332 url('./../../../../assets/Images/SSH.png') no-repeat;
    background-size: contain;
}

div.module-container.Login.Brute.Forcing {
    background: #1A2332 url('./../../../../assets/Images/Login-Brute-Forcing.png') no-repeat;
    background-size: contain;
}

div.module-container.Backdoors {
    background: #1A2332 url('./../../../../assets/Images/Backdoors.png') no-repeat;
    background-size: contain;
}

div.module-container.Social.engineering {
    background: #1A2332 url('./../../../../assets/Images/Social-engineering.png') no-repeat;
    background-size: contain;
}

div.module-container.SQL.injection {
    background: #1A2332 url('./../../../../assets/Images/SQL-injection.png') no-repeat;
    background-size: contain;
}

div.module-container.Wi-Fi.Hijacking {
    background: #1A2332 url('./../../../../assets/Images/Wi-Fi-Hijacking.png') no-repeat;
    background-size: contain;
}

div.module-container.XSS.Attack {
    background: #1A2332 url('./../../../../assets/Images/XSS-Attack.png') no-repeat;
    background-size: contain;
}

/*
Web
*/
div.module-container.HTML-AND-CSS {
    background: #1A2332 url('./../../../../assets/Images/HTML-AND-CSS.png') no-repeat;
    background-size: contain;
}

div.module-container.JavaScript {
    background: #1A2332 url('./../../../../assets/Images/JavaScript.png') no-repeat;
    background-size: contain;
}

div.module-container.React {
    background: #1A2332 url('./../../../../assets/Images/React.png') no-repeat;
    background-size: contain;
}

div.module-container.NodeJS {
    background: #1A2332 url('./../../../../assets/Images/NodeJS.png') no-repeat;
    background-size: contain;
}

div.module-container.PHP {
    background: #1A2332 url('./../../../../assets/Images/PHP.png') no-repeat;
    background-size: contain;
}

/*
SQL
*/

div.module-container.MYSQL {
    background: #1A2332 url('./../../../../assets/Images/MYSQL.png') no-repeat;
    background-size: contain;
}

div.module-container.MSSQL {
    background: #1A2332 url('./../../../../assets/Images/MSSQL.png') no-repeat;
    background-size: contain;
}

div.module-container.MongoDB {
    background: #1A2332 url('./../../../../assets/Images/MongoDB.png') no-repeat;
    background-size: contain;
}

/*
Algorithmics
*/
div.module-container.Swap {
    background: #1A2332 url('./../../../../assets/Images/Swap.png') no-repeat;
    background-size: contain;
}

div.module-container.Programs-for-printing-pyramid-patterns {
    background: #1A2332 url('./../../../../assets/Images/Programs-for-printing-pyramid-patterns.png') no-repeat;
    background-size: contain;
}

div.module-container.Bubble-Sort {
    background: #1A2332 url('./../../../../assets/Images/Bubble-Sort.png') no-repeat;
    background-size: contain;
}

div.module-container.Game-Of-Life {
    background: #1A2332 url('./../../../../assets/Images/Game-Of-Life.png') no-repeat;
    background-size: contain;
}

div.module-container.Stacks {
    background: #1A2332 url('./../../../../assets/Images/Stacks.png') no-repeat;
    background-size: contain;
}

div.module-container.Binary-Tree-BST {
    background: #1A2332 url('./../../../../assets/Images/Binary-Tree-BST.png') no-repeat;
    background-size: contain;
}

div.module-container.DFS-Depth-First-Search {
    background: #1A2332 url('./../../../../assets/Images/DFS-Depth-First-Search.png') no-repeat;
    background-size: contain;
}

div.module-container.DFS-Depth-First-Search {
    background: #1A2332 url('./../../../../assets/Images/DFS-Depth-First-Search.png') no-repeat;
    background-size: contain;
}

div.module-container.BFS-Breadth-First-Search {
    background: #1A2332 url('./../../../../assets/Images/BFS-Breadth-First-Search.png') no-repeat;
    background-size: contain;
}

div.module-container.Linux-RegEx-Terminal-Test {
    background: #1A2332 url('./../../../../assets/Images/Test.png') no-repeat;
    background-size: contain;
}

div.module-container.Networking-Quiz-Test {
    background: #1A2332 url('./../../../../assets/Images/Networking-Test.png') no-repeat;
    background-size: contain;
}
