.para-after-image-editors-nano{
    width: 100%;
    margin-top: 26px;
    height: 40vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/nano.png') no-repeat center;
    background-size: 40%;
    /* border: 1px solid red; */
}

.para-after-image-editors-vim{
    width: 100%;
    margin-top: 26px;
    height: 40vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/vim.png') no-repeat center;
    background-size: 40%;
    /* border: 1px solid red; */
}
