.header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-family: "Libre Baskerville", serif;
    font-weight: 400;
    font-style: normal;

    font-size: 17pt; /*36px;*/
    text-align: center;
    color: #323232; /*#2784a0;*/
    margin-top: 10px;
    height: 17vh;
    /* border:1px solid red; */
}

.heading-text {
    width: 100%;
    text-align: center;
    align-self: flex-start;
    padding-top:13px;
    font-size:1.5vw;
    /* border: 1px solid black; */

}
.heading-text p {
    
    color: white;
    line-height: 13px;
    text-shadow: 2px 2px 4px #000000;
}