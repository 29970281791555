#qustion-container {
    width: 100%;
    margin-right: 7px;
    margin-top: 13px;
    display: flex;
    flex-direction: row;
    align-content: space-evenly;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    direction: rtl;
    color: #402aa5;
    margin-right: 26px;
    padding-bottom: 26px;
    /* border:1px solid red; */
}

#qustion-container p {
    width: 70%;
    height: 26px;
    margin: 0px;
    font-size: 26px;
    align-self: flex-start;
    text-align: right;
    margin-bottom: 7px;
    /* margin-right: 26px; */
    /* border:1px solid red; */
}

#qustion-container button {
    width: 13% !important;
    height: 36px;
    align-self: flex-start;
    font-size: 9pt;
    margin-right: 26px;
    /* border:1px solid red; */
}