#linux-components{
    display: flex;
    justify-content: center;
    width:100%;
    margin-top:3%;
    /* border:1px solid red; */

}

div#linux-components td {
    line-height: 1.5 !important;
}
 h4 {
    color: #7dacb5 ;
 }
