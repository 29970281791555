div#linux-components td {
    direction: rtl;
}

.paper.users {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/users.png') no-repeat 20px 20px;
    background-size: 5%;
}

.paper.users > p > div > main#container > div#terminal {
    height: 7vh !important;
    margin-top: 10px;
}