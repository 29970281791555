.paper.redirects > p > div > main#container > div#terminal {
    height: 7vh !important;
    width: 40vw;
}

span.terminal-output{
    direction: ltr;
    text-align: left;
    background-color: #4C1D3D;
    color: aliceblue;
    width: 58.7%;
    margin-left: 20.6%;
    border:1px solid red;
}
