.paper.medium-display-column.package-actions>div>main#container>div#terminal {
    height: 7vh !important;
}

.paper.package-actions > div>main#container>div#terminal>section#terminal__body>div#terminal__prompt>span#terminal__prompt--cursor {
    min-width: 68%;
    /* border: 1px solid red; */
}

.paper.package-actions > .shell-terminal {
    /* border: 1px solid blue; */
    width: 85% !important;
}

.paper.medium-display-column.package-actions  div#terminal {
    width:55% !important;

}