.hackers-os-img{
    position: relative;
    left: 0px;
    width: 100%;
    display: inline-block;
    height: 28vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/hackers-os.png') no-repeat center bottom;
    background-size: 70%;
    /* border: 1px solid red; */
}

b.c1{
    font-size: 17pt;
}