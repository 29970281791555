html,
body {
    height: 100%;
    margin: 0px;
}

#main-dashboard {
    /* direction: rtl; */
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    /* border:1px solid purple; */

}

.aside {
    position: relative;
    right: 26px;
    border: 1px solid red;
    width: 100%;
    height: 344px;
    background-color: #0C0C1B !important;
    color: #595c97 !important;
    text-decoration: none !important;
    font-size: 26px;
    border-radius: 26%;
    margin: 26px;

}

a {
    /* color: #595c97 !important; */
    /* color: #eea108 !important; */
    color:#7ada3e;
    text-decoration: none !important;
    display: inline-block;
    /* border:1px solid red; */
    width: 10vw;
    height: 3vh;
    /* cursor: url('./../../assets/img/hand1.png'), auto; */
}
a > li.mm-active{
    list-style-type: none;
}
.menu-heading {
    direction: rtl;
    /* background: url('./../../assets/img/logo.svg') 87% 92% no-repeat; */
    background-size: auto;
    color: aquamarine;
    padding: 17px 26px 0px 26px;
}

.menu-heading-text {
    position: relative;
    left: 30px;
    display: inline-block;
}

.aside ul {
    list-style-type: none;
    text-align: right;
}

.aside ul li {
    width: 100%;
}

.aside ul li span.menu-item {
    padding-right: 7px;
}

.aside ul li:hover,
.selected {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: rgba(0, 79, 157, 0.4);
    background-image: none;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    box-sizing: border-box;
    color: rgb(75, 124, 243);
    /* cursor: url('./../../assets/img/hand1.png'), auto; */
    display: block;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-overflow: ellipsis;
    text-size-adjust: 100%;
    transition-delay: 0s;
    transition-duration: 0s;
    transition-property: none;
    transition-timing-function: ease;
    white-space: nowrap;
}