@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono');

body {
    /* background: linear-gradient(45deg, #57003f 0%, #f57453 100%); */
    font-family: Ubuntu;
}

#container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 10vh; */
}

#terminal {
    width: 30vw;
    height: 26vh;
    color: #00fcda;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5)
}

#terminal__bar {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: linear-gradient(#504b45 0%, #3c3b37 100%);
}

#bar__buttons {
    display: flex;
    align-items: center;
}

.bar__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 5px;
    font-size: 8px;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 100%;
    background: linear-gradient(#7d7871 0%, #595953 100%);
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}

.bar__button:hover {
    cursor: pointer;
}

.bar__button:focus {
    outline: none;
}

#bar__button--exit {
    background: linear-gradient(#f37458 0%, #de4c12 100%);
    background-clip: padding-box;
}

#bar__user {
    color: #d5d0ce;
    margin-left: 6px;
    font-size: 14px;
    line-height: 15px;
}

#terminal__body {
    background: rgba(56, 4, 40, 0.9);
    font-family: 'Ubuntu Mono';
    height: calc(100% - 30px);
    padding-top: 2px;
    margin-top: -1px;
}

#terminal__prompt {
    display: flex;
}

#terminal__prompt--user {
    color: #7eda28;
}

#terminal__prompt--location {
    color: #4878c0;
}

#terminal__prompt--bling {
    color: #dddddd;
}

#terminal__prompt--cursor {
    overflow: hidden;
    direction: ltr;
    text-align: left;
    display: block;
    height: 17px;
    width: 50%;
    margin-left: 9px;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(0%);
    animation: typewriter 4s steps(44) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
}

#terminal-result-container {
    /* border:1px solid red; */
    display: flex;
    flex-wrap: wrap;
    
}
#terminal-result-container p {
    margin: 10px 0px;
}

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
    flex-basis: 100%;
    height: 0;
}

.terminal__result {
    margin: 10px 0px;
    text-align: left;
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 76%;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, .75);
    }

    to {
        border-right-color: transparent;
    }
}

@keyframes blink {
    0% {
        background: #ffffff;
    }

    49% {
        background: #ffffff;
    }

    60% {
        background: transparent;
    }

    99% {
        background: transparent;
    }

    100% {
        background: #ffffff;
    }

}

@media (max-width: 600px) {
    #terminal {
        max-height: 90%;
        width: 90%;
    }
}