#clock-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 30%;
    /* background-color: aqua; */
    /* border:2px  solid */
}
#clock {
    font-family: monospace;
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center; 
    backdrop-filter: blur(5px);  
    background-color: hsla(0,0%,100%,0.107); 
}

div.paper.test button.MuiButtonBase-root {
    width: 176px !important;
}