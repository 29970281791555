.paper .threehackerstype,
.frontend-backend-img, .three-hackers-team {

    position: relative;
    left: 0px;
    width: 100%;
    display: inline-block;
    height: 47vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/threehackerstype.png') no-repeat center;
    background-size: 40%;
    /* border: 1px solid red; */

}

.frontend-backend-img {
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/Differentiating-Frontend-From-Backend.png') no-repeat top center;
    background-size: 40%;
    height: 28vh;
}

.three-hackers-team{
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/three-hackers-team.png') no-repeat top left;
    background-size: 40%;
    height: 28vh;
}
