#questionnaire-container {
    display: none;
    /* display: flex; */
    flex-direction: column;
    width: 70%;
    height: fit-content;
    direction: rtl;
    color: black;
    font-size: 13px;
    /* border: 1px solid red; */
}
p#sub-topic {
    font-size: 17pt;
    color: cadetblue;
}
table,
th,
tr,
td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
}

th {
    color: whitesmoke !important;
}

.styled-table {
    border-collapse: collapse;
    margin-right: 26px;
    /* margin: 25px 0; */
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    line-height: 1.43 !important;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.show {
    display: flex !important;
}
.hide {
    display: none;
}
.hide-answer {
    visibility: hidden;
}

.show-answer {
    visibility: visible;
}