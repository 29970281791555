.paper.ls-introduction {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    direction: rtl;
}
.ls-result {
    width: 100%;
    height: 36vh;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/ls.png') no-repeat center 130px;
    background-size: 70%;
    justify-content: space-between;
}
.para-after-image{
    width: 100%;
    margin-top: 26px;
    height: 26vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/manwondering.jpg') no-repeat center;
    background-size: 26%;
    /* border: 1px solid red; */
}
.bold.blue.medium-text{
    color: #12488A !important;
}
.bold.green.medium-text{
    color:#279C67;
}
.bold.red.medium-text{
    color:#9c2731;
}
