.paper.Tor,
.the-hidden-wiki {
    position: relative;
    left: 0px;
    width: 100%;
    display: inline-block;
    height: 17vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/tor.png') no-repeat 7px 7px;
    background-size: 11%;
}

div.deepweb-img {

    /* border: 1px solid red; */
}

.c9>span.c4>a {
    width: 26%;
}

.the-hidden-wiki {
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/hidden-wiki.png') no-repeat 7px 7px;
    background-size: 26%;
}

a.wiki-link {
    font-size: 17pt;
    width: 70%;
    line-height: 84px;
    height: 10vh;
    text-align: left;
    /* border:1px solid red; */
}