.paper {
    margin: 8px;
    width: 70%;
    height: 80vh;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13pt;
    text-align: right;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.paper h4 {
    text-align: center;
}

.paper p {
    margin: 13px;
    font-weight: normal;
}

.paper p b {
    color: #FEAC00;
    display: block;
    margin: 5px;
    margin-top: 26px;
}


.paper span {
    direction: rtl;
    display: block;
    /* margin: 5px; */
}

.paper img {
    width: 21%;
    margin: 10px;

}

.paper.core-principles {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/core.png') no-repeat 50px center;
    background-size: 40%;
    
    filter: brightness(100%) drop-shadow(6px 6px 8px blue) hue-rotate(45deg) saturate(10%);
   
    }