div#subjects-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 80vh;
    /* border: 1px solid red; */
}
div#subjects {
    
}
.MuiSimpleTreeView-root{
  
}
.MuiTreeItem-iconContainer{
    color: #000;
}
.MuiTreeItem-content{
  
}
.MuiTreeItem-label {
    color: #878787;
    text-align: left;
    font-size:21px !important; 
}