p.radio-qustion {
    font-size: 17pt;
}

p.MuiFormHelperText-root {
    font-size: 26pt;
}

img.quiz-image {

    float: left;
    border: none;
    width: 36%;
    /* width: 10%; */
}