.code-panel {
    background-color: #d8e5ee;
    border-radius: 0;
    margin-bottom: 1.25rem;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}
.copy-btn.pull-right{
    cursor: pointer;
}
PRE,
pre>code>span {
    font-family: 'Monotype.com', Courier New, monospace;
    font-size: 0.9em;
}

code span {
    direction: ltr !important;
    text-align: left;
}

.codebox {
    width: 90%;
    background-color: #000000;
    color: #FFFFFF;
}

.codebox pre {
    margin: 0 0 0 20px;
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.example-caption {
    padding: 5px;
    display: inline-block;
    text-align: left;
    font-size: 16px;
    width: 90%;
    min-height: 37px;
    color: #3a3737;

}

.copy-btn {
    border: 0;
    border-left: 1px solid rgba(0, 0, 0, .125);
    background-color: transparent;
    font-family: sans-serif;
    color: #507590;
    font-size: 14px;
    border-radius: 0;
    height: 37px;
    width: 10%;
}

pre[class*=language-] {
    padding: 1em;
    margin: 0;
    overflow: auto;
}

div {
    overflow-wrap: break-word;
}

code[class*=language-],
pre[class*=language-] {
    word-wrap: break-word;
    white-space: pre-wrap;
    /* border: 1px solid red; */
    /* width: 28%; */
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    /* background-color: #507590; */
    /* color: #000;
    background: 0 0;
    text-shadow: 0 1px #fff;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none; */
}

.token.class-name {
    /* color: #1b9aaf; */
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
    /* color: #9a6e3a;
    background: hsla(0, 0%, 100%, .5); */
}

.token.punctuation {
    /* color: #000; */
}

.card-footer:last-child {
    /* border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); */
}

.example-footer {
    /* background-color: transparent;
    border: 0;
    padding: 0; */
}