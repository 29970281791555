.paper.shortcuts {
    color: #005599;
}
.paper.shortcuts p b.align-right{
text-align: right;
}
.paper.shortcuts p b{
    margin-top:0px;
    font-size: 10pt;
    text-align: left;
    text-shadow: 2px 4px 4px rgba(0,0,0,0.2),
    0px -5px 10px rgba(255,255,255,0.15);
}

.paper.shortcuts span.block {
    direction: ltr;
    display: block;
    text-align: left;
}

.paper.shortcuts span {
    display: inline-block;
    margin-top: 0px;
    font-size: 9pt;
    direction: ltr;
}

.normal.black.medium-text {
    color: black;
}

.bold.blue.medium-text {
    color: #12488A !important;
}

.bold.green.medium-text {
    color: #279C67;
}

.bold.orange.medium-text {
    color: #eea108;
}