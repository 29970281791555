p.c9.searchengines, p.c9.searchengines-upload-img {
    text-align: right;
    position: relative;
    left: 5%;
    width: 91%;
    display: block;
    height: 28vh;
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/googleImg.png') no-repeat center;
    background-size:36%;
    /* border:1px solid red; */
}

p.c9.searchengines-upload-img {
    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/google-upload-img.png') no-repeat center;
    height: 42vh;
    background-size:36%;
    
}
