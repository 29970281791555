#terminal {
    width: 30vw;
    height: 13vh;

}

span.red-color {
    display: inline;
    color: red
}

span.bold.blue.medium-text{
    color: blue;
    font-size:17px;
}