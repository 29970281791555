.paper.kill-process > p > b{
    direction: rtl;
}

div#kill-l-image {
    width: 100%;
    height: 40vh;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/kill-l.png') no-repeat center 10px;
    background-size: 70%;
    justify-content: space-between;
    /* border: 1px solid red; */
}