.paper.ugo-num {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    direction: rtl;
    justify-content: flex-start;
    /* justify-content: space-between; */
}

.ugo-num-result h4 {
    color: #279C67;
}

.ugo-num-result {
    width: 100%;
    height: 30;
    /* border:1px solid red; */
}

.ugo-num .para-after-image {
    width: 100%;
    margin-top: 0px;
    height: 18vh;
    align-content: flex-start;
    background: none;
}

.linux-permisssions-numbers-image {
    width: 100%;
    margin-top: 7px;
    height: 50vh;
    align-content: flex-start;
    /* background: none; */

    background: rgb(255, 255, 255) url('./../../../../../../assets/Images/linux-permisssions-numbers.png') no-repeat left bottom;
    background-size: 65%;
    /* border: 1px solid red; */
}

.ugo-num-result span.ltr {
    text-align: left;
    width: 100%;
    /* background-color: #12488A; */
    display: block;
    direction: ltr;
}

.bold.blue.medium-text {
    color: #12488A !important;
}

.bold.green.medium-text {
    color: #279C67;
}

.bold.orange.medium-text {
    color: #eea108;
}