.paper.super-user>p>div>main#container>div#terminal {
    height: 7vh !important;
    width: 32vw;
}

.paper.super-user>p>div>main#container>div#terminal>section#terminal__body>div#terminal__prompt>span#terminal__prompt--cursor {
    min-width: 26%;
    /* border:1px solid red; */
}

div.warning {
    border: 1px dotted #ddd;
    padding: 1em 2em 1em 2em;
    background-color: #eee;
    box-shadow: 0 5px 10px #eee;
    margin: 2em 2em 2em;
    font-size: smaller;
    direction: rtl;
    text-align: right;
}