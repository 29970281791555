div.vertical-menu-container {
    position: sticky;
    margin-top: 0.5%;
    width: 18%;
    top: 70px;
    align-self: flex-start;
    /* border: 1px solid green; */

}

ul.metismenu {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

ul.metismenu li {
    display: block;
    text-align: left;
    width: 100%;

}


ul.metismenu li {
    padding: 0px;
    font-size: 17pt;
}

ul.sub-menu.mm-collapse li.mm-active {
    /* background-image: linear-gradient(#FFFFFF,#FF5C35); */
    /* background-image: linear-gradient(#8b9da9, #fff6e4);
    box-shadow: inset 0 0 100px hsla(0,0%,0%,.3);
    border-radius:51%; */

    background: rgb(105, 155, 200);
    background: -moz-radial-gradient(top left, ellipse cover, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
    background: -webkit-gradient(radial, top left, 0px, top left, 100%, color-stop(0%, rgba(105, 155, 200, 1)), color-stop(57%, rgba(181, 197, 216, 1)));
    background: -webkit-radial-gradient(top left, ellipse cover, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
    background: -o-radial-gradient(top left, ellipse cover, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
    background: -ms-radial-gradient(top left, ellipse cover, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
    background: radial-gradient(ellipse at top left, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#699bc8', endColorstr='#b5c5d8', GradientType=1);
    border-radius:3%;

}

ul.sub-menu mm-collapse li {
    background-color: transparent;
}

svg.svg-inline--fa {
    padding-right: 10px;
    font-size: 26pt;
}



#sidebar-menu ul a {
    width: 100%;
    height: 10vh;
    /* border:1px solid green;  */
}

#sidebar-menu ul li {
    display: block;
    padding: .625rem 1.5rem;
    width: 100%;
    color: #a4b1cd;
    position: relative;
    font-size: 17px;
    transition: all .4s;
    /* border: 1px solid red; */
}



#sidebar-menu ul li ul.sub-menu li {
    /* padding: .4rem 1.5rem .4rem 3.5rem; */
    /* font-size: 14px; */
    color: #a4b1cd;
    color: #1976d2;
}

#sidebar-menu ul li {
    cursor: pointer;
    display: block;
    /* padding: .625rem 1.5rem; */
    color: #a4b1cd;
    position: relative;
    /* font-size: 14.4px; */
    transition: all .4s;
}

#sidebar-menu ul li i {
    display: inline-block;
    min-width: 1.75rem;
    /* padding-bottom: .125em; */
    /* font-size: 1.2rem; */
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #a4b1cd;
    transition: all .4s;
}