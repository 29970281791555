.paper.WhonixInstallation {
    position: relative;
    left: 0px;
    width: 100%;
    display: inline-block;
    height: 28vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/800px-Whonix-concept-illustration.png') no-repeat 0px 3px;
    background-size: 17%;
    /* border: 1px solid red; */
}

#terminal {
    width: 70vw !important;
}
.shell-terminal {
    width: 70vw !important;
}

#terminal__prompt--cursor{
    width:75%;
}