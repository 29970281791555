.paper.tr>div>main#container>div#terminal {
    height: 7vh !important;
    width: 100vw;
}

.paper.tr>div>main#container>div#terminal>section#terminal__body>div#terminal__prompt>span#terminal__prompt--cursor {
    min-width: 57%;
    /* border:1px solid red; */
}

.paper.tr>.shell-terminal {
    /* border: 1px solid blue; */
    margin-left: 26px;
    width: 85% !important;
}