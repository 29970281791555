.Whonix-gateway-Xfce-Setting, .whonix-virtualbox, .whonix-virtualbox-eth1{
    position: relative;
    left: 0px;
    width: 100%;
    display: inline-block;
    height: 42vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/Whonix-gateway-Xfce-Setting.png') no-repeat 0px 3px;
    background-size: 36%;
    /* border: 1px solid red; */
}

#terminal {
    /* width: 33vw !important; */
}
.shell-terminal {
    /* width: 33vw !important; */
}

.whonix-virtualbox{
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/whonix-virtualbox.png') no-repeat center;
    background-size: 56%;
    height: 60vh;
}

.whonix-virtualbox-eth1{
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/whonix-virtualbox-eth1.png') no-repeat center;
    background-size: 40%;
    height: 40vh;
}