p.c9.list-items.VPN b {
    text-align: right;
}
p.c9.list-items.VPN {
    text-align: left;
    position: relative;
    left: 5%;
    width: 65%;
    display: inline-block;
    height: 28vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/vypervpn.png') no-repeat center right;
    background-size:13%;
    /* border:1px solid red; */

}
p.c9.list-items.VPN span{
   direction: ltr;
}

p.c9.list-items.VPN a{
    display: inline-block;
    width: 50vw;
    direction:ltr;
    text-align: left;
    font-size: 17pt;
    color:cadetblue;
    /* border:1px solid red; */
    }