.image-on-page-nmap-metasploit{
    background: rgb(255, 255, 255) url('./../../../../../../../assets/Images/nmap-output-1.png') no-repeat 1px 145px;
    height: 26vh;
    background-size: 21%;  
}

.image-on-page-nmap-metasploit-ufw{
    background: rgb(255, 255, 255) url('./../../../../../../../assets/Images/nmap-output-2.png') no-repeat 1px 126px;
    height: 26vh;
    background-size: 32%;   
}