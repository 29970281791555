#shell-system-inof-linux{
    display: flex;
    justify-content: center;
    width:100%;
    margin-top:3%;
    /* border:1px solid red; */

}

td {
    line-height: 0.7 !important;
}