.topicviewersidebar-container {
    width: 17%;
    min-height: 100%;
    /* border: 1px solid blue; */
}

div.MuiTreeItem-label {
    /* border: 1px solid green; */
    /* background-color: #d0daec; */
    /* margin-top: 1.5rem!important;
    margin-bottom: .5rem!important; */
    font-size: 13pt !important;
}