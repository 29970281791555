p.tor-status-img{
    /* border:1px solid red; */
}
.tor-status-img{
    position: relative;
    left: 0px;
    width: 100%;
    display: inline-block;
    height: 50vh;
    background: rgb(255, 255, 255) url('./../../../../../assets/Images/tor-status-img.png') no-repeat bottom left;
    background-size: 56%;
    /* border: 1px solid red; */
}