.paper.medium-display-column.packages-dpkg>div>main#container>div#terminal {
    height: 7vh !important;
    width: 160vw;
}

.paper.packages-dpkg>div>main#container>div#terminal>section#terminal__body>div#terminal__prompt>span#terminal__prompt--cursor {
    min-width: 100%;
    /* border:1px solid red; */
}

.paper.medium-display-column.packages-dpkg > .shell-terminal {
    /* border: 1px solid blue; */
    margin-left: 26px;
    width: 85% !important;
}