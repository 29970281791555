.paper.ls-l {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    direction: rtl;
}
.ls-a-result {
    width: 100%;
    height: 36.5vh;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/ls-a.png') no-repeat center 130px;
    background-size: 70%;
    justify-content: space-between;
}
.ls-a .para-after-image{
    width: 100%;
    margin-top: 26px;
    height: 28vh;
    align-content: flex-start;
    background:  rgb(255, 255, 255) url('./../../../../../../assets/Images/hidden.png') no-repeat 26px bottom;
    background-size: 13%;
    /* border: 1px solid red; */
}
.bold.blue.medium-text{
    color: #12488A !important;
}
.bold.green.medium-text{
    color:#279C67;
}
.bold.orange.medium-text{
    color:#eea108;
}